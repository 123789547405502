exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-patents-js": () => import("./../../../src/pages/blogs/patents.js" /* webpackChunkName: "component---src-pages-blogs-patents-js" */),
  "component---src-pages-blogs-trademark-patents-js": () => import("./../../../src/pages/blogs/trademark-patents.js" /* webpackChunkName: "component---src-pages-blogs-trademark-patents-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-companies-alphabet-js": () => import("./../../../src/pages/companies/alphabet.js" /* webpackChunkName: "component---src-pages-companies-alphabet-js" */),
  "component---src-pages-companies-amazon-js": () => import("./../../../src/pages/companies/amazon.js" /* webpackChunkName: "component---src-pages-companies-amazon-js" */),
  "component---src-pages-companies-apple-js": () => import("./../../../src/pages/companies/apple.js" /* webpackChunkName: "component---src-pages-companies-apple-js" */),
  "component---src-pages-companies-meta-js": () => import("./../../../src/pages/companies/meta.js" /* webpackChunkName: "component---src-pages-companies-meta-js" */),
  "component---src-pages-companies-microsoft-js": () => import("./../../../src/pages/companies/microsoft.js" /* webpackChunkName: "component---src-pages-companies-microsoft-js" */),
  "component---src-pages-companies-samsung-js": () => import("./../../../src/pages/companies/samsung.js" /* webpackChunkName: "component---src-pages-companies-samsung-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-for-founders-entrepreneurs-js": () => import("./../../../src/pages/for-founders-entrepreneurs.js" /* webpackChunkName: "component---src-pages-for-founders-entrepreneurs-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-new-js": () => import("./../../../src/pages/home-new.js" /* webpackChunkName: "component---src-pages-home-new-js" */),
  "component---src-pages-home-quiz-js": () => import("./../../../src/pages/home-quiz.js" /* webpackChunkName: "component---src-pages-home-quiz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-3-d-printing-js": () => import("./../../../src/pages/industries/3-d-printing.js" /* webpackChunkName: "component---src-pages-industries-3-d-printing-js" */),
  "component---src-pages-industries-animal-health-js": () => import("./../../../src/pages/industries/animal-health.js" /* webpackChunkName: "component---src-pages-industries-animal-health-js" */),
  "component---src-pages-industries-artificial-intelligence-ai-js": () => import("./../../../src/pages/industries/artificial-intelligence-ai.js" /* webpackChunkName: "component---src-pages-industries-artificial-intelligence-ai-js" */),
  "component---src-pages-industries-autonomous-vehicles-js": () => import("./../../../src/pages/industries/autonomous-vehicles.js" /* webpackChunkName: "component---src-pages-industries-autonomous-vehicles-js" */),
  "component---src-pages-industries-biopharmaceuticals-js": () => import("./../../../src/pages/industries/biopharmaceuticals.js" /* webpackChunkName: "component---src-pages-industries-biopharmaceuticals-js" */),
  "component---src-pages-industries-blockchain-and-fintech-js": () => import("./../../../src/pages/industries/blockchain-and-fintech.js" /* webpackChunkName: "component---src-pages-industries-blockchain-and-fintech-js" */),
  "component---src-pages-industries-cannabis-patents-and-trademarks-js": () => import("./../../../src/pages/industries/cannabis-patents-and-trademarks.js" /* webpackChunkName: "component---src-pages-industries-cannabis-patents-and-trademarks-js" */),
  "component---src-pages-industries-chemical-products-js": () => import("./../../../src/pages/industries/chemical-products.js" /* webpackChunkName: "component---src-pages-industries-chemical-products-js" */),
  "component---src-pages-industries-communications-js": () => import("./../../../src/pages/industries/communications.js" /* webpackChunkName: "component---src-pages-industries-communications-js" */),
  "component---src-pages-industries-consumer-products-js": () => import("./../../../src/pages/industries/consumer-products.js" /* webpackChunkName: "component---src-pages-industries-consumer-products-js" */),
  "component---src-pages-industries-digital-healthcare-js": () => import("./../../../src/pages/industries/digital-healthcare.js" /* webpackChunkName: "component---src-pages-industries-digital-healthcare-js" */),
  "component---src-pages-industries-electronics-js": () => import("./../../../src/pages/industries/electronics.js" /* webpackChunkName: "component---src-pages-industries-electronics-js" */),
  "component---src-pages-industries-food-science-packaging-js": () => import("./../../../src/pages/industries/food-science-packaging.js" /* webpackChunkName: "component---src-pages-industries-food-science-packaging-js" */),
  "component---src-pages-industries-industrial-and-agricultural-biotech-js": () => import("./../../../src/pages/industries/industrial-and-agricultural-biotech.js" /* webpackChunkName: "component---src-pages-industries-industrial-and-agricultural-biotech-js" */),
  "component---src-pages-industries-industrial-products-js": () => import("./../../../src/pages/industries/industrial-products.js" /* webpackChunkName: "component---src-pages-industries-industrial-products-js" */),
  "component---src-pages-industries-infectious-diseases-js": () => import("./../../../src/pages/industries/infectious-diseases.js" /* webpackChunkName: "component---src-pages-industries-infectious-diseases-js" */),
  "component---src-pages-industries-internet-js": () => import("./../../../src/pages/industries/internet.js" /* webpackChunkName: "component---src-pages-industries-internet-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-industries-medical-device-patents-js": () => import("./../../../src/pages/industries/medical-device-patents.js" /* webpackChunkName: "component---src-pages-industries-medical-device-patents-js" */),
  "component---src-pages-industries-metaverse-js": () => import("./../../../src/pages/industries/metaverse.js" /* webpackChunkName: "component---src-pages-industries-metaverse-js" */),
  "component---src-pages-industries-nanotechnology-js": () => import("./../../../src/pages/industries/nanotechnology.js" /* webpackChunkName: "component---src-pages-industries-nanotechnology-js" */),
  "component---src-pages-industries-patent-news-js": () => import("./../../../src/pages/industries/patent-news.js" /* webpackChunkName: "component---src-pages-industries-patent-news-js" */),
  "component---src-pages-industries-prescription-otc-drugs-js": () => import("./../../../src/pages/industries/prescription-otc-drugs.js" /* webpackChunkName: "component---src-pages-industries-prescription-otc-drugs-js" */),
  "component---src-pages-industries-software-js": () => import("./../../../src/pages/industries/software.js" /* webpackChunkName: "component---src-pages-industries-software-js" */),
  "component---src-pages-industries-therapeutic-antibodies-js": () => import("./../../../src/pages/industries/therapeutic-antibodies.js" /* webpackChunkName: "component---src-pages-industries-therapeutic-antibodies-js" */),
  "component---src-pages-ipchecker-js": () => import("./../../../src/pages/ipchecker.js" /* webpackChunkName: "component---src-pages-ipchecker-js" */),
  "component---src-pages-patent-analyzer-js": () => import("./../../../src/pages/patent-analyzer.js" /* webpackChunkName: "component---src-pages-patent-analyzer-js" */),
  "component---src-pages-patentfaqs-how-to-respond-to-office-action-js": () => import("./../../../src/pages/patentfaqs/how-to-respond-to-office-action.js" /* webpackChunkName: "component---src-pages-patentfaqs-how-to-respond-to-office-action-js" */),
  "component---src-pages-patentfaqs-ip-basics-js": () => import("./../../../src/pages/patentfaqs/ip-basics.js" /* webpackChunkName: "component---src-pages-patentfaqs-ip-basics-js" */),
  "component---src-pages-patentfaqs-js": () => import("./../../../src/pages/patentfaqs.js" /* webpackChunkName: "component---src-pages-patentfaqs-js" */),
  "component---src-pages-patentfaqs-patent-basics-how-to-set-up-an-account-with-the-uspto-js": () => import("./../../../src/pages/patentfaqs/patent-basics/how-to-set-up-an-account-with-the-uspto.js" /* webpackChunkName: "component---src-pages-patentfaqs-patent-basics-how-to-set-up-an-account-with-the-uspto-js" */),
  "component---src-pages-patentfaqs-patent-basics-js": () => import("./../../../src/pages/patentfaqs/patent-basics.js" /* webpackChunkName: "component---src-pages-patentfaqs-patent-basics-js" */),
  "component---src-pages-patentfaqs-patent-basics-will-i-need-a-patent-attorney-or-agent-for-the-application-process-js": () => import("./../../../src/pages/patentfaqs/patent-basics/will-i-need-a-patent-attorney-or-agent-for-the-application-process.js" /* webpackChunkName: "component---src-pages-patentfaqs-patent-basics-will-i-need-a-patent-attorney-or-agent-for-the-application-process-js" */),
  "component---src-pages-patentfaqs-the-uspto-has-issued-your-patent-js": () => import("./../../../src/pages/patentfaqs/the-uspto-has-issued-your-patent.js" /* webpackChunkName: "component---src-pages-patentfaqs-the-uspto-has-issued-your-patent-js" */),
  "component---src-pages-patentfaqs-utility-patent-application-filing-a-utility-patent-application-js": () => import("./../../../src/pages/patentfaqs/utility-patent-application/filing-a-utility-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-utility-patent-application-filing-a-utility-patent-application-js" */),
  "component---src-pages-patentfaqs-utility-patent-application-js": () => import("./../../../src/pages/patentfaqs/utility-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-utility-patent-application-js" */),
  "component---src-pages-patentfaqs-what-is-a-provisional-patent-application-ppa-after-you-submit-a-provisional-patent-application-js": () => import("./../../../src/pages/patentfaqs/what-is-a-provisional-patent-application-ppa/after-you-submit-a-provisional-patent-application.js" /* webpackChunkName: "component---src-pages-patentfaqs-what-is-a-provisional-patent-application-ppa-after-you-submit-a-provisional-patent-application-js" */),
  "component---src-pages-patentfaqs-what-is-a-provisional-patent-application-ppa-js": () => import("./../../../src/pages/patentfaqs/what-is-a-provisional-patent-application-ppa.js" /* webpackChunkName: "component---src-pages-patentfaqs-what-is-a-provisional-patent-application-ppa-js" */),
  "component---src-pages-premeeting-js": () => import("./../../../src/pages/premeeting.js" /* webpackChunkName: "component---src-pages-premeeting-js" */),
  "component---src-pages-quiz-index-js": () => import("./../../../src/pages/quiz/index.js" /* webpackChunkName: "component---src-pages-quiz-index-js" */),
  "component---src-pages-quiz-intro-js": () => import("./../../../src/pages/quiz/intro.js" /* webpackChunkName: "component---src-pages-quiz-intro-js" */),
  "component---src-pages-quiz-q-1-js": () => import("./../../../src/pages/quiz/q-1.js" /* webpackChunkName: "component---src-pages-quiz-q-1-js" */),
  "component---src-pages-quiz-q-2-js": () => import("./../../../src/pages/quiz/q-2.js" /* webpackChunkName: "component---src-pages-quiz-q-2-js" */),
  "component---src-pages-quiz-q-3-js": () => import("./../../../src/pages/quiz/q-3.js" /* webpackChunkName: "component---src-pages-quiz-q-3-js" */),
  "component---src-pages-quiz-q-4-js": () => import("./../../../src/pages/quiz/q-4.js" /* webpackChunkName: "component---src-pages-quiz-q-4-js" */),
  "component---src-pages-quiz-q-5-js": () => import("./../../../src/pages/quiz/q-5.js" /* webpackChunkName: "component---src-pages-quiz-q-5-js" */),
  "component---src-pages-quiz-result-js": () => import("./../../../src/pages/quiz/result.js" /* webpackChunkName: "component---src-pages-quiz-result-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-trademark-faqs-js": () => import("./../../../src/pages/trademark-faqs.js" /* webpackChunkName: "component---src-pages-trademark-faqs-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

